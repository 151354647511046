
import MIcon from "@/components/MIcon.vue";
import MMessageItem from "@/components/message/MMessageItem.vue";
import MTimePickerModal from "@/components/message/MTimePickerModal.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import {
  sendPostbackEvent,
  sendTextMessage,
  sendImageMessage,
  getAllMessagesRead
} from "@/api/message";
import { uploadImage } from "@/api/storage";
import { saveErrorLog } from "@/api/error";
import Compressor from "compressorjs";

@Options({
  components: {
    MIcon,
    MMessageItem,
    MTimePickerModal
  }
})
export default class Message extends Vue {
  newMessage = "";
  files: File[] = [];
  showTimePickerModal = false;
  timePickerData = "";

  get fileUrls(): string[] {
    return this.files.map(file => URL.createObjectURL(file));
  }

  get dynamicSendIconCss(): string {
    if (this.newMessage.length > 0) {
      return "text-primary-500 cursor-pointer hover:text-primary-600";
    } else {
      return "text-gray-400";
    }
  }

  get messages() {
    return store.state.messages.filter(
      message => message.data.from.type !== "system"
    );
  }

  get messageLimit(): number {
    return store.state.messageLimit;
  }

  get messageFinished(): boolean {
    return store.state.messageFinished;
  }

  get messageLoading(): boolean {
    return store.state.messageLoading;
  }

  addImage(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let index = 0;
    while (index < event.target.files.length) {
      if (this.files.length == 3) {
        alert("同時に送信できる画像は 3 個までです");
        break;
      }
      const item = event.target.files.item(index);
      if (item) {
        if (item.size > 1000 * 1000 * 5) {
          alert("5 MB以上のファイルサイズの画像は送信できません");
        } else {
          this.files.push(item);
        }
      }
      index++;
    }
  }

  removeImage(index: number) {
    const file = this.files[index];
    this.files = this.files.filter(item => item.name !== file.name);
  }

  getMoreMessages() {
    store.dispatch("getMoreMessage");
  }

  sendMessageByPostback(message: string) {
    this.newMessage = message;
    this.sendMessage();
  }

  async sendPostback(data: string) {
    const student = store.state.student;
    if (!student) {
      return;
    }

    try {
      await sendPostbackEvent(student.ref, data);
    } catch (e) {
      alert(e);
      await saveErrorLog(
        student,
        e.code,
        e.message,
        "Failed to send postback event"
      );
    }
  }

  openTimerPicker(data: string) {
    this.timePickerData = data;
    this.showTimePickerModal = true;
  }

  async sendTime(newerTimerEnd: string) {
    const student = store.state.student;
    if (!student) {
      return;
    }
    try {
      await sendPostbackEvent(student.ref, this.timePickerData, {
        newerTimerEnd
      });
    } catch (e) {
      alert(e);
      await saveErrorLog(
        student,
        e.code,
        e.message,
        "Failed to send postback event"
      );
    }
  }

  async sendMessage() {
    const student = store.state.student;
    if (!student || this.newMessage.length === 0) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "メッセージ送信中...");
    const messageText = this.newMessage;
    this.newMessage = "";
    try {
      await sendTextMessage(
        student.ref,
        {
          type: "student",
          userId: student.ref.id,
          name: student.data.name
        },
        messageText
      );
    } catch (e) {
      alert(
        `メッセージの送信に失敗しました。\n時間を置いてから再度実行してみてください。\n\n${e}`
      );
      if (!this.newMessage) {
        this.newMessage = messageText;
      }
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to send message"
      );
    }
    if (this.files.length === 0) {
      store.commit("SET_LOADING", false);
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像送信中...");

    try {
      const imagePathes = await Promise.all(
        this.files.map(
          file =>
            new Promise<string>((resolve, reject) => {
              new Compressor(file, {
                quality: 0.5,
                convertSize: 300 * 1000, // 300KB 以上の png は jpg に変換する
                success(result) {
                  const fileToUpload = result as File;
                  uploadImage(fileToUpload, store.state.student!.ref.id)
                    .then(resolve)
                    .catch(reject);
                },
                error: reject
              });
            })
        )
      );
      await Promise.all(
        imagePathes.map(path =>
          sendImageMessage(
            store.state.student!.ref,
            {
              type: store.state.role!.data.type,
              userId: store.state.role!.ref.id,
              name: store.state.student?.data.name ?? ""
            },
            path
          )
        )
      );
      store.commit("SET_LOADING", false);
    } catch (e) {
      store.commit("SET_LOADING", false);
      alert(`画像メッセージの送信に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to send image message"
      );
      return;
    }

    this.files = [];
  }

  async getAllMessagesRead() {
    const student = store.state.student;
    if (!student) throw new Error("No student found");
    if (store.state.unreadMessagesLength === 0) return;
    await getAllMessagesRead(student.ref);
  }

  async mounted(): Promise<void> {
    try {
      await this.getAllMessagesRead();
    } catch (e) {
      console.error("すべてのメッセージを既読にできませんでした" + e);
    }
  }

  // メッセージ画面を開いている最中に新着メッセージがあった時、そこから離れるタイミングで未読件数を消す
  async beforeUnmount(): Promise<void> {
    try {
      await this.getAllMessagesRead();
    } catch (e) {
      console.error("すべてのメッセージを既読にできませんでした" + e);
    }
  }
}
