<template>
  <div class="h-full flex flex-col">
    <div class="px-4 flex-1 flex flex-col-reverse overflow-y-scroll">
      <m-message-item
        v-for="message in messages"
        :key="message.ref.id"
        :item="message"
        @send="sendMessageByPostback"
        @postback="sendPostback"
        @timepicker="openTimerPicker"
      />
      <div
        v-if="messages.length >= messageLimit && !messageFinished"
        class="flex justify-center py-4"
      >
        <div
          class="px-4 py-2 rounded-md text-gray-700 text-sm underline transition-colors duration-300 cursor-pointer hover:bg-gray-100"
          @click="getMoreMessages"
        >
          <span v-show="!messageLoading">さらに読み込む</span>
          <span v-show="messageLoading">読み込み中...</span>
        </div>
      </div>
    </div>
    <div class="w-full px-4 py-2 border-b border-gray-300 bg-white shadow-md">
      <div
        v-if="fileUrls.length > 0"
        class="flex w-full mb-2 overflow-x-scroll mb-1"
      >
        <div
          v-for="(url, i) in fileUrls"
          :key="url"
          class="relative w-20 h-20 mr-2"
        >
          <img
            :src="url"
            alt="Upload Image"
            class="w-16 h-16 mx-2 my-2 object-cover rounded-md border-2 border-gray-200"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            class="absolute right-0 top-0 w-5 h-5 text-gray-500 transition-colors duration-300 cursor-pointer hover:text-black"
            @click="removeImage(i)"
          >
            <path
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            />
          </svg>
        </div>
      </div>
      <div class="flex-none flex items-center">
        <label
          for="image"
          class="flex-none transition-all duration-300 text-gray-500 cursor-pointer"
        >
          <m-icon type="picture" />
          <input
            id="image"
            type="file"
            accept="image/*"
            name="image"
            multiple
            class="hidden"
            @change="addImage"
          />
        </label>
        <textarea
          v-model="newMessage"
          name="message"
          placeholder="メッセージを入力"
          class="mx-3 flex-1 bg-primary-100 rounded border border-primary-100 focus:border-primary-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300"
        />
        <div
          class="flex-none transform rotate-90 transition-all duration-300"
          :class="dynamicSendIconCss"
          @click="sendMessage"
        >
          <m-icon type="send" />
        </div>
      </div>
    </div>
  </div>
  <m-time-picker-modal
    v-if="showTimePickerModal"
    title="終了予定時刻を入力"
    description="新しく終了予定時刻を設定してください。"
    @close="showTimePickerModal = false"
    @send="sendTime"
  />
</template>

<script lang="ts">
import MIcon from "@/components/MIcon.vue";
import MMessageItem from "@/components/message/MMessageItem.vue";
import MTimePickerModal from "@/components/message/MTimePickerModal.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import {
  sendPostbackEvent,
  sendTextMessage,
  sendImageMessage,
  getAllMessagesRead
} from "@/api/message";
import { uploadImage } from "@/api/storage";
import { saveErrorLog } from "@/api/error";
import Compressor from "compressorjs";

@Options({
  components: {
    MIcon,
    MMessageItem,
    MTimePickerModal
  }
})
export default class Message extends Vue {
  newMessage = "";
  files: File[] = [];
  showTimePickerModal = false;
  timePickerData = "";

  get fileUrls(): string[] {
    return this.files.map(file => URL.createObjectURL(file));
  }

  get dynamicSendIconCss(): string {
    if (this.newMessage.length > 0) {
      return "text-primary-500 cursor-pointer hover:text-primary-600";
    } else {
      return "text-gray-400";
    }
  }

  get messages() {
    return store.state.messages.filter(
      message => message.data.from.type !== "system"
    );
  }

  get messageLimit(): number {
    return store.state.messageLimit;
  }

  get messageFinished(): boolean {
    return store.state.messageFinished;
  }

  get messageLoading(): boolean {
    return store.state.messageLoading;
  }

  addImage(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let index = 0;
    while (index < event.target.files.length) {
      if (this.files.length == 3) {
        alert("同時に送信できる画像は 3 個までです");
        break;
      }
      const item = event.target.files.item(index);
      if (item) {
        if (item.size > 1000 * 1000 * 5) {
          alert("5 MB以上のファイルサイズの画像は送信できません");
        } else {
          this.files.push(item);
        }
      }
      index++;
    }
  }

  removeImage(index: number) {
    const file = this.files[index];
    this.files = this.files.filter(item => item.name !== file.name);
  }

  getMoreMessages() {
    store.dispatch("getMoreMessage");
  }

  sendMessageByPostback(message: string) {
    this.newMessage = message;
    this.sendMessage();
  }

  async sendPostback(data: string) {
    const student = store.state.student;
    if (!student) {
      return;
    }

    try {
      await sendPostbackEvent(student.ref, data);
    } catch (e) {
      alert(e);
      await saveErrorLog(
        student,
        e.code,
        e.message,
        "Failed to send postback event"
      );
    }
  }

  openTimerPicker(data: string) {
    this.timePickerData = data;
    this.showTimePickerModal = true;
  }

  async sendTime(newerTimerEnd: string) {
    const student = store.state.student;
    if (!student) {
      return;
    }
    try {
      await sendPostbackEvent(student.ref, this.timePickerData, {
        newerTimerEnd
      });
    } catch (e) {
      alert(e);
      await saveErrorLog(
        student,
        e.code,
        e.message,
        "Failed to send postback event"
      );
    }
  }

  async sendMessage() {
    const student = store.state.student;
    if (!student || this.newMessage.length === 0) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "メッセージ送信中...");
    const messageText = this.newMessage;
    this.newMessage = "";
    try {
      await sendTextMessage(
        student.ref,
        {
          type: "student",
          userId: student.ref.id,
          name: student.data.name
        },
        messageText
      );
    } catch (e) {
      alert(
        `メッセージの送信に失敗しました。\n時間を置いてから再度実行してみてください。\n\n${e}`
      );
      if (!this.newMessage) {
        this.newMessage = messageText;
      }
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to send message"
      );
    }
    if (this.files.length === 0) {
      store.commit("SET_LOADING", false);
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像送信中...");

    try {
      const imagePathes = await Promise.all(
        this.files.map(
          file =>
            new Promise<string>((resolve, reject) => {
              new Compressor(file, {
                quality: 0.5,
                convertSize: 300 * 1000, // 300KB 以上の png は jpg に変換する
                success(result) {
                  const fileToUpload = result as File;
                  uploadImage(fileToUpload, store.state.student!.ref.id)
                    .then(resolve)
                    .catch(reject);
                },
                error: reject
              });
            })
        )
      );
      await Promise.all(
        imagePathes.map(path =>
          sendImageMessage(
            store.state.student!.ref,
            {
              type: store.state.role!.data.type,
              userId: store.state.role!.ref.id,
              name: store.state.student?.data.name ?? ""
            },
            path
          )
        )
      );
      store.commit("SET_LOADING", false);
    } catch (e) {
      store.commit("SET_LOADING", false);
      alert(`画像メッセージの送信に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to send image message"
      );
      return;
    }

    this.files = [];
  }

  async getAllMessagesRead() {
    const student = store.state.student;
    if (!student) throw new Error("No student found");
    if (store.state.unreadMessagesLength === 0) return;
    await getAllMessagesRead(student.ref);
  }

  async mounted(): Promise<void> {
    try {
      await this.getAllMessagesRead();
    } catch (e) {
      console.error("すべてのメッセージを既読にできませんでした" + e);
    }
  }

  // メッセージ画面を開いている最中に新着メッセージがあった時、そこから離れるタイミングで未読件数を消す
  async beforeUnmount(): Promise<void> {
    try {
      await this.getAllMessagesRead();
    } catch (e) {
      console.error("すべてのメッセージを既読にできませんでした" + e);
    }
  }
}
</script>
