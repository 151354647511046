
import MBaseButton from "@/components/MBaseButton.vue";
import MIcon from "@/components/MIcon.vue";
import {
  Message,
  ButtonTemplateMessage,
  BasicButtonTemplateMessage,
  ConfirmTemplateMessage,
  PostbackAction
} from "@/entities/message";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBaseButton,
    MIcon
  },
  emits: ["postback", "send", "timepicker"],
  props: {
    item: Object
  }
})
export default class MMessageItem extends Vue {
  item!: Message;
  isImageAvailable = true;

  async mounted() {
    if (!this.isImageMessage) {
      this.isImageAvailable = false;
    } else {
      if (!this.item.data.messageText) {
        this.isImageAvailable = false;
        return;
      }
      const img = new Image();
      img.onerror = () => {
        this.isImageAvailable = false;
      };
      img.src = this.item.data.messageText;
    }
  }

  get isImageMessage() {
    return this.item.data.type === "image";
  }

  get templateMessage(): ButtonTemplateMessage | null {
    if (this.item.data.type !== "button_template") {
      return null;
    }
    if (!this.item.data.template) {
      return null;
    }
    return this.item.data.template;
  }

  get basicButtonTemplateMessage(): BasicButtonTemplateMessage | null {
    if (this.item.data.type !== "basic_button_template") {
      return null;
    }
    if (!this.item.data.basicButtonTemplate) {
      return null;
    }
    return this.item.data.basicButtonTemplate;
  }

  get confirmTemplateMessage(): ConfirmTemplateMessage | null {
    if (this.item.data.type !== "confirm_template") {
      return null;
    }
    if (!this.item.data.confirmTemplate) {
      return null;
    }
    return this.item.data.confirmTemplate;
  }

  get imageSrc(): string {
    if (this.item.data.type !== "image") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get videoSrc(): string {
    if (this.item.data.type !== "video") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get audioSrc(): string {
    if (this.item.data.type !== "audio") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get fileSrc(): string {
    if (this.item.data.type !== "file") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get icon() {
    if (this.item.data.from.type === "student") {
      return require("@/assets/student-icon.svg");
    } else if (this.item.data.from.type === "bot") {
      return require("@/assets/bot-icon.png");
    } else {
      return require("@/assets/tutor-icon.svg");
    }
  }

  get timeText(): string {
    const now = dayjs().locale("ja");
    const messageTime = dayjs(this.item.data.timestamp * 1000).locale("ja");
    if (now.year() !== messageTime.year()) {
      return messageTime.format("YYYY/M/D HH:mm");
    } else if (
      now.month() === messageTime.month() &&
      now.date() === messageTime.date()
    ) {
      return "今日 " + messageTime.format("HH:mm");
    } else if (
      now.month() === messageTime.month() &&
      now.date() === messageTime.date() + 1
    ) {
      return "昨日 " + messageTime.format("HH:mm");
    } else {
      return messageTime.format("M/D HH:mm");
    }
  }

  get dynamicMetaCss() {
    if (this.item.data.from.type === "student") {
      return "flex-row-reverse";
    } else {
      return "flex-row";
    }
  }

  get dynamicContentCss() {
    if (this.item.data.from.type === "student") {
      return "mr-10 ml-3";
    } else {
      return "mr-3 ml-10";
    }
  }

  optimizeUrl(url: string): string {
    const liffId = process.env.VUE_APP_LIFF_ID as string;
    const texts = url.split(`https://liff.line.me/${liffId}`);
    if (texts.length === 2) {
      return texts[1];
    } else {
      return url;
    }
  }

  openPicker(mode: "date" | "time" | "datetime", data: string) {
    if (mode === "time") {
      this.$emit("timepicker", data);
    } else {
      alert("このアクションには現在対応していません");
    }
  }

  handlePostback(action: PostbackAction) {
    if (action.displayText) {
      this.$emit("send", action.displayText);
    }
    this.$emit("postback", action.data);
  }

  timepicker(data: string) {
    this.$emit("timepicker", data);
  }
}
