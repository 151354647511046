<template>
  <div class="flex flex-col mb-8">
    <div class="flex items-center mb-2" :class="dynamicMetaCss">
      <img :src="icon" alt="Icon" class="w-8 h-8 rounded-full" />
      <span class="text-sm font-medium mx-2">{{ item.data.from.name }}</span>
      <span class="text-xs text-gray-400">{{ timeText }}</span>
    </div>
    <div
      v-if="templateMessage"
      class="px-1 pt-1 max-w-xs flex flex-col rounded-md bg-white"
      :class="dynamicContentCss"
    >
      <img
        v-if="templateMessage.thumbnailUrl"
        :src="templateMessage.thumbnailUrl"
        alt="thumbnail"
        class="object-cover"
      />
      <div class="w-full py-3 px-1 flex flex-col">
        <p v-if="templateMessage.title" class="mb-1 text-sm font-bold">
          {{ templateMessage.title }}
        </p>
        <p class="text-xs text-gray-500">{{ item.data.messageText }}</p>
      </div>
      <a
        :href="templateMessage.buttonUri"
        class="w-full py-1 mb-3 text-center text-sm text-primary-500 transition-all duration-300 hover:text-primary-600"
      >
        {{ templateMessage.buttonLabel }}
      </a>
    </div>
    <div
      v-else-if="basicButtonTemplateMessage"
      class="px-1 pt-1 max-w-xs flex flex-col rounded-md bg-white"
      :class="dynamicContentCss"
    >
      <img
        v-if="basicButtonTemplateMessage.thumbnailUrl"
        :src="basicButtonTemplateMessage.thumbnailUrl"
        alt="thumbnail"
        class="object-cover"
      />
      <div class="w-full py-3 px-1 flex">
        <div
          v-if="basicButtonTemplateMessage.title"
          class="w-full flex flex-col"
        >
          <p
            v-if="basicButtonTemplateMessage.title"
            class="mb-1 text-sm font-bold"
          >
            {{ basicButtonTemplateMessage.title }}
          </p>
          <p class="text-xs text-gray-500">{{ item.data.messageText }}</p>
        </div>
        <p v-else class="text-sm">{{ item.data.messageText }}</p>
      </div>
      <div
        v-for="action in basicButtonTemplateMessage.actions"
        :key="action.label"
        class="w-full pb-3 flex justify-center"
      >
        <m-base-button
          v-if="action.type === 'uri'"
          color="primary-500"
          hover-color="primary-600"
          font-weight="normal"
          text
          :link="optimizeUrl(action.uri)"
        >
          {{ action.label }}
        </m-base-button>
      </div>
    </div>
    <div
      v-else-if="confirmTemplateMessage"
      class="px-1 pt-1 max-w-xs flex flex-col rounded-md bg-white"
      :class="dynamicContentCss"
    >
      <div class="w-full py-3 px-1 flex">
        <p class="text-sm">{{ confirmTemplateMessage.text }}</p>
      </div>
      <div class="flex">
        <div
          v-for="action in confirmTemplateMessage.actions"
          :key="action.label"
          class="w-full pb-3 flex-1 flex flex-col"
        >
          <div v-if="action.type === 'uri'" class="w-full flex justify-center">
            <m-base-button
              color="primary-500"
              hover-color="primary-600"
              font-weight="normal"
              text
              :link="optimizeUrl(action.uri)"
            >
              {{ action.label }}
            </m-base-button>
          </div>
          <div
            v-else-if="action.type === 'postback'"
            class="w-full flex justify-center"
          >
            <m-base-button
              color="primary-500"
              hover-color="primary-600"
              font-weight="normal"
              text
              @click="handlePostback(action)"
            >
              {{ action.label }}
            </m-base-button>
          </div>
        </div>
      </div>
    </div>
    <img
      v-else-if="imageSrc && isImageAvailable"
      :src="imageSrc"
      alt="Content Image"
      class="object-contain"
      :class="dynamicContentCss"
    />
    <div
      v-else-if="imageSrc && !isImageAvailable"
      class="object-contain p-3 rounded-md bg-white text-sm font-normal text-yellow-300 flex"
      :class="dynamicContentCss"
    >
      <m-icon type="warning" size="6" class="mr-2" />
      <div class="text-gray-500 font-medium">この画像は削除されています</div>
    </div>
    <a
      v-else-if="videoSrc"
      :href="videoSrc"
      target="_blank"
      rel="noopener noreferrer"
      class="flex items-center justify-between p-4 bg-white text-sm text-primary-500 rounded cursor-pointer"
      :class="dynamicContentCss"
    >
      <span>送信された動画を表示</span>
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        class="w-4 h-4 mr-1"
      >
        <path
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </a>
    <a
      v-else-if="audioSrc"
      :href="audioSrc"
      target="_blank"
      rel="noopener noreferrer"
      class="flex items-center justify-between p-4 bg-white text-sm text-primary-500 rounded cursor-pointer"
      :class="dynamicContentCss"
    >
      <span>送信された音声を表示</span>
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        class="w-4 h-4 mr-1"
      >
        <path
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </a>
    <a
      v-else-if="fileSrc"
      :href="fileSrc"
      target="_blank"
      rel="noopener noreferrer"
      class="flex items-center justify-between p-4 bg-white text-sm text-primary-500 rounded cursor-pointer"
      :class="dynamicContentCss"
    >
      <span>送信されたファイルを表示</span>
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        class="w-4 h-4 mr-1"
      >
        <path
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </a>
    <div
      v-else
      class="p-4 bg-white text-sm rounded whitespace-pre-wrap break-all"
      :class="dynamicContentCss"
    >
      {{ item.data.messageText }}
    </div>
  </div>
</template>

<script lang="ts">
import MBaseButton from "@/components/MBaseButton.vue";
import MIcon from "@/components/MIcon.vue";
import {
  Message,
  ButtonTemplateMessage,
  BasicButtonTemplateMessage,
  ConfirmTemplateMessage,
  PostbackAction
} from "@/entities/message";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBaseButton,
    MIcon
  },
  emits: ["postback", "send", "timepicker"],
  props: {
    item: Object
  }
})
export default class MMessageItem extends Vue {
  item!: Message;
  isImageAvailable = true;

  async mounted() {
    if (!this.isImageMessage) {
      this.isImageAvailable = false;
    } else {
      if (!this.item.data.messageText) {
        this.isImageAvailable = false;
        return;
      }
      const img = new Image();
      img.onerror = () => {
        this.isImageAvailable = false;
      };
      img.src = this.item.data.messageText;
    }
  }

  get isImageMessage() {
    return this.item.data.type === "image";
  }

  get templateMessage(): ButtonTemplateMessage | null {
    if (this.item.data.type !== "button_template") {
      return null;
    }
    if (!this.item.data.template) {
      return null;
    }
    return this.item.data.template;
  }

  get basicButtonTemplateMessage(): BasicButtonTemplateMessage | null {
    if (this.item.data.type !== "basic_button_template") {
      return null;
    }
    if (!this.item.data.basicButtonTemplate) {
      return null;
    }
    return this.item.data.basicButtonTemplate;
  }

  get confirmTemplateMessage(): ConfirmTemplateMessage | null {
    if (this.item.data.type !== "confirm_template") {
      return null;
    }
    if (!this.item.data.confirmTemplate) {
      return null;
    }
    return this.item.data.confirmTemplate;
  }

  get imageSrc(): string {
    if (this.item.data.type !== "image") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get videoSrc(): string {
    if (this.item.data.type !== "video") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get audioSrc(): string {
    if (this.item.data.type !== "audio") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get fileSrc(): string {
    if (this.item.data.type !== "file") {
      return "";
    }
    if (!this.item.data.messageText!.includes("https://")) {
      return "";
    } else {
      return this.item.data.messageText!;
    }
  }

  get icon() {
    if (this.item.data.from.type === "student") {
      return require("@/assets/student-icon.svg");
    } else if (this.item.data.from.type === "bot") {
      return require("@/assets/bot-icon.png");
    } else {
      return require("@/assets/tutor-icon.svg");
    }
  }

  get timeText(): string {
    const now = dayjs().locale("ja");
    const messageTime = dayjs(this.item.data.timestamp * 1000).locale("ja");
    if (now.year() !== messageTime.year()) {
      return messageTime.format("YYYY/M/D HH:mm");
    } else if (
      now.month() === messageTime.month() &&
      now.date() === messageTime.date()
    ) {
      return "今日 " + messageTime.format("HH:mm");
    } else if (
      now.month() === messageTime.month() &&
      now.date() === messageTime.date() + 1
    ) {
      return "昨日 " + messageTime.format("HH:mm");
    } else {
      return messageTime.format("M/D HH:mm");
    }
  }

  get dynamicMetaCss() {
    if (this.item.data.from.type === "student") {
      return "flex-row-reverse";
    } else {
      return "flex-row";
    }
  }

  get dynamicContentCss() {
    if (this.item.data.from.type === "student") {
      return "mr-10 ml-3";
    } else {
      return "mr-3 ml-10";
    }
  }

  optimizeUrl(url: string): string {
    const liffId = process.env.VUE_APP_LIFF_ID as string;
    const texts = url.split(`https://liff.line.me/${liffId}`);
    if (texts.length === 2) {
      return texts[1];
    } else {
      return url;
    }
  }

  openPicker(mode: "date" | "time" | "datetime", data: string) {
    if (mode === "time") {
      this.$emit("timepicker", data);
    } else {
      alert("このアクションには現在対応していません");
    }
  }

  handlePostback(action: PostbackAction) {
    if (action.displayText) {
      this.$emit("send", action.displayText);
    }
    this.$emit("postback", action.data);
  }

  timepicker(data: string) {
    this.$emit("timepicker", data);
  }
}
</script>
